import store from "../store/basic-store";
import {
  TransportAction,
  TransportSubAction,
  CreateGroupPayload,
} from "../types";
import { wsSend } from "./websocket";

export async function createGroupAction(payload: CreateGroupPayload) {
  const { name, description, type } = payload;
  console.log("payload: ", payload);

  if (!name && !description && !type) {
    throw new Error("Please include all fields");
  }
  try {
    await wsSend({
      action: TransportAction.GROUP,
      subAction: TransportSubAction.CREATE,
      payload,
    });
  } catch (err) {
    store.setApi({ key: "group", error: (err as Error).message });
    throw new Error("Invalid Group Action");
  }
}

export async function getGroupsAction(payload: { userId: number }) {
  const { userId } = payload;

  if (!userId) {
    throw new Error("Please include a userId");
  }
  try {
    await wsSend({
      action: TransportAction.GROUPS,
      subAction: TransportSubAction.GET,
      payload,
    });
  } catch (err) {
    store.setApi({ key: "groups", error: (err as Error).message });
    throw new Error("Invalid Groups Action");
  }
}

export async function getGroupAction(payload: { id: string }) {
  const { id } = payload;

  if (!id) {
    throw new Error("Please include an id");
  }
  try {
    await wsSend({
      action: TransportAction.GROUP,
      subAction: TransportSubAction.GET,
      payload,
    });
  } catch (err) {
    store.setApi({ key: "group", error: (err as Error).message });
    throw new Error("Invalid Group Action");
  }
}

export async function kickGroupAction(payload: { id: string }) {
  const { id } = payload;

  if (!id) {
    throw new Error("Please include an id to kick");
  }
  try {
    await wsSend({
      action: TransportAction.GROUP,
      subAction: TransportSubAction.LEAVE,
      payload,
    });
  } catch (err) {
    store.setApi({ key: "leave_group", error: (err as Error).message });
    throw new Error("Invalid Group Action");
  }
}

export async function inviteGroupAction(payload: {
  userId: number;
  groupId: string;
}) {
  const { userId, groupId } = payload;

  if (!userId || !groupId) {
    throw new Error("Please include an id to invite");
  }
  try {
    await wsSend({
      action: TransportAction.GROUP,
      subAction: TransportSubAction.INVITE,
      payload,
    });
  } catch (err) {
    store.setApi({ key: "invite_group", error: (err as Error).message });
    throw new Error("Invalid Group Action");
  }
}

export async function findUser(payload: { search: string }) {
  const { search } = payload;

  if (!search) {
    throw new Error("Please include a search term");
  }
  try {
    await wsSend({
      action: TransportAction.USER,
      subAction: TransportSubAction.SEARCH,
      payload,
    });
  } catch (err) {
    store.setApi({ key: "users", error: (err as Error).message });
    throw new Error("Invalid User Action");
  }
}
