import { useEffect, useState } from "react";
import { JsonObj } from "../types/general.types";
import { v4 } from "uuid";

interface Listener {
  key: string;
  id: string;
  callback: (obj: any) => void;
}

class BasicStore {
  public store: JsonObj;
  constructor() {
    this.store = {};
  }
  private listeners: Listener[] = [];

  private emit(key: string) {
    this.listeners.forEach((obj) => {
      if (obj.key === key) {
        obj.callback(this.store[key]);
      }
    });
    // console.log("emit this.store: ", JSON.stringify(this.store));
  }
  public listen<T>(key: string, callback: (obj: T) => void): string {
    const id = v4();
    this.listeners.push({ key, id, callback });
    return id;
  }

  public stopListening(id: string) {
    const index = this.listeners.findIndex((val) => val.id === id);
    if (index > -1) {
      // only splice array when item is found
      this.listeners.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  public setApi(payload: {
    key: string;
    loading?: boolean;
    data?: any;
    error?: any;
  }) {
    const { key, loading, data, error } = payload;
    console.log("setApi: ", payload);

    this.store[key] = { loading: !!loading, data, error };
    this.emit(key);
  }

  public get<T = any>(key: string): T {
    return this.store[key];
  }
  public set(key: string, value?: string | number | boolean | null | JsonObj) {
    this.store[key] = value;
    this.emit(key);
  }
  public destroy() {
    const keys = Object.keys(this.store);
    this.store = {};
    keys.forEach((key) => {
      console.log("emit: ", key);
      this.emit(key);
    });
  }
}

const store = new BasicStore();

export function useStore<T>(key: string): T | null {
  const [value, setValue] = useState<T | null>(store.get(key));

  useEffect(() => {
    const id = store.listen(key, (obj: T) => {
      setValue(obj);
    });

    return () => {
      store.stopListening(id);
    };
  }, []);
  return value;
}

export default store;
