import { Button, Layout, Modal } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import { HeaderMenu } from '../components/header-menu'
import store, { useStore } from '../store/basic-store'
import { User } from '../types'
import { useEffect, useState } from 'react'
import { sendEmailCode } from '../service/auth'

export function MainPage() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const emailVerifiedShown = useStore('emailVerifiedShown')
  const user = useStore<User>('user')

  useEffect(() => {
    let emailSent = false
    if (user && !user?.email_verified && (!user.email_code_timeout || Date.now() < user.email_code_timeout)) {
      emailSent = true
    }

    if (user && !emailVerifiedShown && !isModalOpen && !emailSent) {
      store.set('emailVerifiedShown', true)
      setIsModalOpen(true)
    }
  }, [user, isModalOpen, emailVerifiedShown])

  const handleOk = () => {
    setIsModalOpen(false)
    sendEmailCode()
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Layout>
      <Header>
        <h1>Fractured Nations Games</h1>
      </Header>
      <Content>
        <HeaderMenu selected="home" />
        <Content>
          <Modal
            title="Email not Verified"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                No
              </Button>,
              <Button key="submit" type="primary" onClick={handleOk}>
                Yes
              </Button>,
            ]}
          >
            <p>Would you like to verify your email?</p>
          </Modal>

          <div>
            <p>
              <b>Welcome to Fractured Nations Games</b>
            </p>
            <p>I think this will be build attempt 5. I really should do something with this.</p>
          </div>

          <div style={{ padding: '1rem', maxWidth: '75rem', display: 'none' }}>
            <p>
              <b>Welcome to Fractured Nations Games</b>
            </p>
            <p style={{ textAlign: 'left' }}>
              Fractured Nations focuses on facilitating multiplayer board games. Our main features will include a lightning fast gaming
              experience, family friendly environment, with a robust set of classic and new games. There will be gaming ladders,
              championship brackets, groups and guilds, and safe chat features for the young folks.
            </p>

            <p style={{ textAlign: 'left' }}>We plan to eventually release to web, mobile, and steam.</p>

            <p style={{ textAlign: 'left' }}>
              Version 1.0 will include all listed Game Features and at least 3 games in a Beta Release state.
            </p>

            <div style={{ textAlign: 'left', padding: '1rem' }}>
              <h3>Development Phases</h3>
              <p>Games and Features </p>
            </div>

            <div style={{ textAlign: 'left', padding: '1rem' }}>
              <h3>Game Features</h3>
              <ul>
                <BetaRelease>Player Chat </BetaRelease>
                <AlphaRelease>Player Profile </AlphaRelease>
                <UnderDevelopment>Groups and Guilds </UnderDevelopment>
                <InPipeline>Achievements </InPipeline>
                <InPipeline>Friends </InPipeline>
                <InPipeline>Ranking Ladders </InPipeline>
                <InPipeline>Championship Brackets </InPipeline>
                <InPipeline>Safe Chat </InPipeline>
                <InPipeline>Server Credits </InPipeline>
              </ul>
            </div>
            <div style={{ textAlign: 'left', padding: '1rem' }}>
              <h3>Games</h3>
              <ul>
                <AlphaRelease>Tic-Tac-Toe </AlphaRelease>
                <UnderDevelopment>Checkers </UnderDevelopment>
                <UnderDevelopment>Wild Uno </UnderDevelopment>
                <InPipeline>Camping with Children </InPipeline>
                <InPipeline>Chess </InPipeline>
                <InPipeline>4 Chess </InPipeline>
                <InPipeline>Connect Squares </InPipeline>
                <InPipeline>Dots </InPipeline>
                <InPipeline>Hearts </InPipeline>
                <InPipeline>Land War</InPipeline>
                <InPipeline>Scorched Earth </InPipeline>
                <InPipeline>Spades </InPipeline>
                <InPipeline>Thud </InPipeline>
                <InPipeline>Tradewars Empires </InPipeline>
                <InPipeline>Vengeance Pact </InPipeline>
              </ul>
            </div>
          </div>
        </Content>
      </Content>
    </Layout>
  )
}

function Released({ children }: { children: string | JSX.Element }) {
  return (
    <li style={{ fontWeight: 'bold' }}>
      {children}
      <span style={{ color: 'green' }}>(Released)</span>
    </li>
  )
}

function BetaRelease({ children }: { children: string | JSX.Element }) {
  return (
    <li>
      {children}
      <span style={{ color: 'blue' }}>( Beta Release )</span>
    </li>
  )
}

function AlphaRelease({ children }: { children: string | JSX.Element }) {
  return (
    <li>
      {children}
      <span style={{ color: 'purple' }}>( Alpha Release )</span>
    </li>
  )
}

function UnderDevelopment({ children }: { children: string | JSX.Element }) {
  return (
    <li>
      {children}
      <span style={{ color: 'orange' }}>( Under Development )</span>
    </li>
  )
}

function InPipeline({ children }: { children: string | JSX.Element }) {
  return (
    <li>
      {children}
      <span style={{ color: 'gray' }}>(In Pipeline)</span>
    </li>
  )
}
