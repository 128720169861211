import { useEffect, useState } from "react";
import { Button, Layout, Modal, Table } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { HeaderMenu } from "../components/header-menu";
import { useStore } from "../store/basic-store";
import { User } from "../types";
import { getGroupAction, inviteGroupAction } from "../service/group.action";
import { useParams } from "react-router-dom";
import { GroupUser, MyGroup } from "../types/group.types";
import { ColumnsType } from "antd/es/table";
import { UserSelect } from "../components/user-select";

export function GroupPage() {
  const [invite, setInvite] = useState(false);
  const [leave, setLeave] = useState(false);
  const [kick, setKick] = useState<GroupUser>();
  const userSearch = useStore<GroupUser[]>("userSearch");

  const user = useStore<User>("user");
  const { id } = useParams();
  const group = useStore<MyGroup>(`group_${id}`);
  const groupName = group?.name || "Group";
  const users = group?.users || [];
  const me = users.find((u) => u.personId === user?.id);

  useEffect(() => {
    if (user && id) {
      getGroupAction({ id });
    }
  }, [user]);

  const kickUser = (personId: number) => {
    const k = users.find((u) => u.personId === personId);
    if (k) {
      setKick(k);
      setLeave(true);
    }
  };

  const inviteUser = () => {
    setInvite(true);
  };

  const columns: ColumnsType<GroupUser> = [
    {
      title: "Username",
      key: "username",
      width: "60%",
      render: (u: GroupUser) => <>{u.username}</>,
    },
    {
      title: "Role",
      key: "role",
      width: "20%",
      render: (u: GroupUser) => <>{u.role}</>,
    },
    {
      title: "Kick",
      key: "kick",
      render: (u: GroupUser) => (
        <>
          {me?.role === "OWNER" ? (
            <Button onClick={() => kickUser(u.personId)}>
              {user?.id !== u.personId ? "Kick" : "Leave"}
            </Button>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <Layout>
      <InviteModel
        visible={invite}
        handleOk={async (arr: string[]) => {
          setInvite(false);
          console.log("arr: ", arr);
          if (userSearch?.length && arr?.length) {
            arr.forEach((username) => {
              console.log("username: ", username);
              const user = userSearch.find(
                (u) => u.username.toLowerCase() === username.toLowerCase()
              );
              console.log("user: ", user);
              if (user?.personId && id) {
                inviteGroupAction({ userId: user.personId, groupId: id });
              }
            });
          }
          // TODO Map User names to PersonId. Send to server
          //
        }}
        handleCancel={() => {
          setInvite(false);
        }}
      />
      <KickModel
        visible={leave}
        handleOk={async () => {
          setLeave(false);
          setKick(undefined);
        }}
        handleCancel={() => {
          setLeave(false);
          setKick(undefined);
        }}
        me={me}
        kickUser={kick}
      />
      <Header>
        <h1>{groupName}</h1>
      </Header>
      <Content>
        <HeaderMenu selected="groups" />
        <Content style={{ padding: "1rem", textAlign: "right" }}>
          {me?.role === "OWNER" ? (
            <Button onClick={() => inviteUser()}>Invite</Button>
          ) : null}
          <Table
            columns={columns}
            dataSource={users || []}
            style={{ margin: "2rem" }}
          />
        </Content>
      </Content>
    </Layout>
  );
}

function KickModel({
  visible,
  handleOk,
  handleCancel,
  me,
  kickUser,
}: {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  me?: GroupUser;
  kickUser?: GroupUser;
}) {
  const title =
    me?.personId === kickUser?.personId ? "Leave Group" : "Kick User";
  const text =
    me?.personId === kickUser?.personId
      ? "Are you sure you want to leave this group?"
      : "Do you want to kick this user?";
  return (
    <>
      <Modal
        title={title}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {text}
      </Modal>
    </>
  );
}

function InviteModel({
  visible,
  handleOk,
  handleCancel,
}: {
  visible: boolean;
  handleOk: (arr: string[]) => void;
  handleCancel: () => void;
}) {
  const title = "Invite User";
  const [selected, setSelected] = useState<string[]>([]);

  const clickOk = () => {
    if (selected.length) {
      handleOk(selected);
    }
  };
  return (
    <>
      <Modal
        title={title}
        open={visible}
        onOk={clickOk}
        onCancel={handleCancel}
      >
        <div>
          <UserSelect onSelect={setSelected} />
        </div>
      </Modal>
    </>
  );
}
