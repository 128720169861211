import { Button, Layout, Table, Modal, Form, Input, Select } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { HeaderMenu } from "../components/header-menu";
import { useStore } from "../store/basic-store";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { createGroupAction, getGroupsAction } from "../service/group.action";
import { User } from "../types";

interface MyGroup {
  id: string;
  description: string;
  name: string;
  type: string;
  role?: string;
}

const { Option } = Select;

export function GroupsPage() {
  const myGroups = useStore<MyGroup[]>("myGroups");
  const user = useStore<User>("user");
  const nav = useNavigate();
  const [visible, setVisible] = useState(false);
  const [selVal, setSelVal] = useState();
  const [valid, setValid] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      getGroupsAction({ userId: user?.id });
    }
  }, [user]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
    const values = form.getFieldsValue();
    console.log(values);
    createGroupAction(values);
    setValid(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setVisible(false);
    setValid(false);
    form.resetFields();
  };

  const columns: ColumnsType<MyGroup> = [
    {
      title: "Name",
      key: "name",
      width: "30%",
      render: (group: MyGroup) => <>{group.name}</>,
    },
    {
      title: "Description",
      key: "description",
      width: "40%",
      render: (group: MyGroup) => <>{group.description}</>,
    },
    {
      title: "Type",
      key: "type",
      width: "15%",
      render: (group: MyGroup) => <>{group.type}</>,
    },
    {
      title: "Role",
      key: "role",
      width: "15%",
      render: (group: MyGroup) => <>{group.role}</>,
    },
  ];

  return (
    <Layout>
      <Modal
        title="New Group"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: !valid }}
      >
        <Form
          form={form}
          onValuesChange={(values) => {
            setValid(!!(values.name && values.description && values.groupType));
          }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input your name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Please input a description" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="type"
            name="groupType"
            rules={[{ required: true, message: "Please select a group type" }]}
          >
            <Select
              placeholder="Select a group type"
              onChange={(val) => {
                form.setFieldValue("groupType", val);
                setSelVal(val);
                const values = form.getFieldsValue();
                setValid(!!(values.name && values.description && val));
              }}
            >
              <Option default value="FAMILY">
                Family
              </Option>
              <Option value="GUILD">Guild</Option>
            </Select>
            <sub>
              {selVal === "FAMILY"
                ? "A group of loosely related people. Must be less than 20 people"
                : "A group of people"}
            </sub>
          </Form.Item>
        </Form>
      </Modal>
      <Header>
        <h1>Groups</h1>
      </Header>
      <Content>
        <HeaderMenu selected="groups" />
        <Content style={{ padding: "1rem" }}>
          <div style={{ textAlign: "right" }}>
            <Button onClick={showModal}>Add Group</Button>
          </div>
          <Table
            onRow={(record) => {
              return {
                onClick: async (e) => {
                  nav(`/groups/${record.id}`);
                },
              };
            }}
            columns={columns}
            dataSource={myGroups || []}
            style={{ margin: "2rem" }}
          />
        </Content>
      </Content>
    </Layout>
  );
}
