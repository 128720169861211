import { Layout, Menu, MenuProps } from 'antd'
import MenuItem from 'antd/es/menu/MenuItem'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from '../store/basic-store'
import { User } from '../types'
import { logoutAction } from '../service/auth'
import { Content } from 'antd/es/layout/layout'
import Sider from 'antd/es/layout/Sider'

export type MenuTypes = 'home' | 'login' | 'my-games' | 'games' | 'groups'
export function HeaderMenu({ selected }: { selected: MenuTypes }) {
  const navigate = useNavigate()
  const [current, setCurrent] = useState<MenuTypes>(selected)
  const user = useStore<User>('user')
  const myGames = useStore<{ id: string }[]>('my-games')
  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key as MenuTypes)
  }

  return (
    <Layout>
      <Content style={{ background: 'white', lineHeight: '45px' }}>
        <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal">
          <MenuItem key="home" onClick={() => navigate('/')}>
            Home
          </MenuItem>
          {user ? (
            <>
              <MenuItem key="games" onClick={() => navigate('/games')}>
                Games
              </MenuItem>

              {myGames?.length ? (
                <MenuItem key="my-games" onClick={() => navigate('/my-games')}>
                  My Games
                </MenuItem>
              ) : null}

              <MenuItem key="groups" onClick={() => navigate('/groups')}>
                Groups
              </MenuItem>

              <MenuItem key="logout" onClick={() => logoutAction()}>
                Logout
              </MenuItem>
            </>
          ) : (
            <MenuItem key="dlist" onClick={() => navigate('/dlist')}>
              Epic Dividend List
            </MenuItem>
          )}
        </Menu>
      </Content>
      <Sider className="header-greeting" style={{ background: 'white', lineHeight: '45px' }}>
        <div>{user ? <div onClick={() => navigate('/profile')}>Welcome {user.username}</div> : 'Greetings Programs'}</div>
      </Sider>
    </Layout>
  )
}

/*
(
            <MenuItem key="login" onClick={() => navigate("/login")}>
              Login
            </MenuItem>
          )
*/
