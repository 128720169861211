import store from '../store/basic-store'
import { wsSend } from './websocket'
import { TransportAction, TransportSubAction, User } from '../types'

export async function getDList(user?: User | null): Promise<void> {
  console.log('Send getDList Action')
  try {
    store.setApi({ key: 'dlistAction', loading: true })

    await wsSend({
      action: user ? TransportAction.USER : TransportAction.PUBLIC,
      subAction: TransportSubAction.GET,
      payload: {
        resource: 'dlist',
      },
    })
  } catch (err) {
    console.log('Err: ', err)
    store.setApi({ key: 'dlistAction', error: (err as Error).message })
    throw new Error('Invalid Request for dlist')
  }
}

export async function getTickerTypes(): Promise<void> {
  console.log('tickerTypesAction ===>')
  store.setApi({ key: 'tickerTypesAction', loading: true })

  try {
    await wsSend({
      action: TransportAction.PUBLIC,
      subAction: TransportSubAction.GET,
      payload: {
        resource: 'tickerTypes',
      },
    })
  } catch (err) {
    store.setApi({ key: 'tickerTypesAction', error: (err as Error).message })
    throw new Error('Invalid Request for tickerTypes')
  }
}
