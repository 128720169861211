import { GeneralError, JsonObj } from './general.types'

/**
 * Enum: TransportSubAction
 * Description: Enum for the subActions that can be sent in a Transport object.
 */
export enum TransportSubAction {
  ACTION = 'ACTION',
  CODE = 'CODE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  DESTROY = 'DESTROY',
  END = 'END',
  ERROR = 'ERROR',
  FORFEIT = 'FORFEIT',
  GET = 'GET',
  INVITE = 'INVITE',
  JOIN = 'JOIN',
  LEAVE = 'LEAVE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  MESSAGE = 'MESSAGE',
  REFRESH = 'REFRESH',
  SEARCH = 'SEARCH',
  SEND = 'SEND',
  SIGNUP = 'SIGNUP',
  START = 'START',
  STORE = 'STORE',
  TURN = 'TURN',
}

/**
 * Enum: TransportAction
 * Description: Enum for the actions that can be sent in a Transport object.
 */
export enum TransportAction {
  AUTH = 'AUTH',
  ERROR = 'ERROR',
  GROUP = 'GROUP',
  GROUPS = 'GROUPS',
  MESSAGE = 'MESSAGE',
  NOTIFICATION = 'NOTIFICATION',
  PUBLIC = 'PUBLIC',
  ROOM = 'ROOM',
  UNKNOWN = 'UNKNOWN',
  USER = 'USER',
  ZONE = 'ZONE',
}

/**
 * Interface: Transport
 * Description: Interface for the Transport object.
 *
 * @property auth: string - The authentication JWT token.
 * @property action: TransportAction - The action to be taken.
 * @property subAction: TransportSubAction - The optional subAction to be taken.
 * @property message: string - An optional message to be sent.
 * @property payload: T - The optional payload to be sent.
 * @property error: GeneralError - An optional error object.
 *
 */
export interface Transport<T = JsonObj> {
  auth?: string
  action: TransportAction
  subAction?: TransportSubAction
  message?: string
  payload?: T
  error?: GeneralError
}
