import store from "../store/basic-store";
import {
  TransportAction,
  TransportSubAction,
  MessagePayload,
  MessageTarget,
} from "../types";
import { wsSend } from "./websocket";

export async function sendMessageAction(payload: MessagePayload) {
  const { message, target } = payload;
  const { personId, zone, area, room } = target;
  if (!message) {
    throw new Error("No Message to send");
  }
  if (!personId && !zone && !area && !room) {
    throw new Error("Please include a target");
  }
  try {
    payload.target.path = buildTargetPath(target);
    await wsSend({ action: TransportAction.MESSAGE, payload });
  } catch (err) {
    store.setApi({ key: "message", error: (err as Error).message });
    throw new Error("Invalid Message Action");
  }
}

export async function getMessageAction(payload: MessagePayload) {
  const { target, source } = payload;
  const { personId, zone, area, room } = target;

  if (!source) {
    throw new Error("Please include a source");
  }
  if (!personId && !zone && !area && !room) {
    throw new Error("Please include a target");
  }
  try {
    await wsSend({
      action: TransportAction.MESSAGE,
      payload,
      subAction: TransportSubAction.GET,
    });
  } catch (err) {
    store.setApi({ key: "message", error: (err as Error).message });
    throw new Error("Invalid Message Action");
  }
}

export function buildTargetPath(target: MessageTarget): string {
  const { personId, zone, area, room } = target;

  if (personId) {
    return `person/${personId}`;
  }
  let rtn = `zone/${zone}`;
  if (!area) {
    return rtn;
  }
  rtn += `/area/${area}`;
  if (!room) {
    return rtn;
  }
  rtn += `/room/${room}`;
  return rtn;
}

export function sendFriendRequest() {}
