export interface TickerType {
  asset_class: 'stocks' | 'options' | 'crypto' | 'fx' | 'indices'
  code: string
  description: string
  locale: string
}

export interface TickerTypes {
  count: number
  request_id: string
  results: TickerType[]
  status: string
  lastCall: number
}

export interface StockStatus {
  T: string // The exchange symbol that this item is traded under.
  v: number // The trading volume of the symbol in the given time period.
  vw: number // The volume weighted average price.
  o: number // The open price for the symbol in the given time period.
  c: number // The close price for the symbol in the given time period.
  h: number // The highest price for the symbol in the given time period.
  l: number // The lowest price for the symbol in the given time period.
  t: number // The Unix Msec timestamp for the end of the aggregate window.
  n: number // The number of transactions in the aggregate window.
  otc?: boolean // Whether or not this aggregate is for an OTC ticker. This field will be left off if false.
}

export interface Dividend {
  cash_amount: number
  declaration_date: string
  dividend_type: 'CD' | 'SC' | 'LT' | 'ST' // Query by the type of dividend. Dividends that have been paid and/or are expected to be paid on consistent schedules are denoted as CD. Special Cash dividends that have been paid that are infrequent or unusual, and/or can not be expected to occur in the future are denoted as SC.
  ex_dividend_date: string
  frequency: number
  id: string
  pay_date: string
  record_date: string
  ticker: string
}

export enum TickerTypeCode {
  CS = 'CS', // Common Stock
  PFD = 'PFD', // Preferred Stock
  WARRANT = 'WARRANT',
  RIGHT = 'RIGHT',
  BOND = 'BOND',
  ETF = 'ETF', // Exchange Traded Fund
  ETN = 'ETN', // Exchange Traded Note
  ETV = 'ETV', // Exchange Traded Vehicle
  SP = 'SP',
  ADRC = 'ADRC',
  ADRP = 'ADRP',
  ADRW = 'ADRW',
  ADRR = 'ADRR',
  FUND = 'FUND',
  BASKET = 'BASKET',
  UNIT = 'UNIT',
  LT = 'LT',
  OS = 'OS', // Ordanary Share
  GDR = 'GDR',
  OTHER = 'OTHER',
  NYRS = 'NYRS',
  AGEN = 'AGEN',
  EQLK = 'EQLK',
  ETS = 'ETS', // Single Security ETF
}

export interface Stock extends StockStatus {
  dividends: Dividend[]
  frequency?: number
  yield?: number
  fullYield?: number
  divStatus: 'REGULAR' | 'IRREGULAR' | 'UNFULFILLED' | 'OVERFILLED'
  name: string
  tickerType: TickerTypeCode
  market: string
}
export enum AccountLevel {
  NORMAL = 'NORMAL',
  IRON = 'IRON',
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  DIAMOND = 'DIAMOND',
}
export interface DListPrefs {
  id: number
  account: { level: AccountLevel; expiration: string }
}
