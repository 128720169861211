import { ZoneId } from "../types";

export function ZoneToName(zone: ZoneId): string {
  switch (zone) {
    case ZoneId.TIC_TAC_TOE:
      return "Tic Tac Toe";
  }
}

export function capitalize(value: string, splitBy = " "): string {
  return value
    .split(splitBy)
    .map((str) => {
      const word = str.trim().toLowerCase();
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
}
