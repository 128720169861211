import { Button, Layout, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { GameStore, User } from "../types";
import { useStore } from "../store/basic-store";
import { useNavigate } from "react-router-dom";
import { joinGameAction } from "../service/game.action";
import { useEffect, useState } from "react";

export function LobbyLayout({ games }: { games: GameStore[] }) {
  const user = useStore<User>("user");
  const nav = useNavigate();
  const [joined, setJoined] = useState<string | null>(null);
  const myGames = games.filter(
    (game) => !!game.players.find((p) => p.id === user?.id)
  );
  games.sort((game1, game2) => {
    // If My Game
    // If start Date
  });

  useEffect(() => {
    if (joined) {
      const game = games.find((g) => g.id === joined);
      const player = game?.players.find((p) => p.id === user?.id);
      if (game && player) {
        setJoined(null);
        nav(`/games/tic-tac-toe/game/${game?.id}`);
      }
    }
  }, [user, games, joined]);

  const columns: ColumnsType<GameStore> = [
    {
      title: "Name",
      key: "name",
      width: "30%",
      render: (game: GameStore) => <>{game.name}</>,
    },
    {
      title: "Description",
      key: "Description",
      width: "40%",
      render: (game: GameStore) => <>{game.description}</>,
    },
    {
      title: "Players",
      key: "Players",
      width: "20%",
      render: (game: GameStore) => {
        return <>{game.players.map((player) => player.username).join(", ")}</>;
      },
    },
    {
      title: "Join",
      key: "Join",
      width: "10%",
      render: (game: GameStore) => (
        <div>
          {!game.players.find((p) => p.id === user?.id) ? (
            <Button>Join</Button>
          ) : (
            <h4>Joined</h4>
          )}
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <Table
        onRow={(record, rIdx) => {
          return {
            onClick: async (e) => {
              console.log("On Click");
              if (user) {
                console.log("Has User");
                if (record.players.find((player) => player.id === user.id)) {
                  console.log("in Game");
                  nav(`/games/tic-tac-toe/game/${record.id}`);
                } else if (record.players.length === 1) {
                  console.log("Join Game");
                  setJoined(record.id);
                  await joinGameAction(record.id, record.type);
                } else {
                  console.log("Game Full"); // TODO message
                }
              }
            },
          };
        }}
        columns={columns}
        dataSource={games}
        style={{ margin: "0 2rem" }}
      />
    </Layout>
  );
}
