import { useNavigate } from "react-router-dom";
import { Scores, User, ZoneId } from "../types";
import { useStore } from "../store/basic-store";
import Layout from "antd/es/layout";
import { Content, Header } from "antd/es/layout/layout";
import { ReactNode, useEffect } from "react";
import { BackHome } from "../components/back-home";
import Avatar from "antd/es/avatar";
import { UserOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { getZoneScores } from "../service/game.action";
import { capitalize } from "../util/string";
import { getGroupsAction } from "../service/group.action";
import { MyGroup } from "../types/group.types";

export function ProfilePage() {
  const navigate = useNavigate();
  const user = useStore<User>("user");
  const groupsArr = useStore<MyGroup[]>("myGroups");
  const scores = useStore<Scores>("scores");

  console.log("groupsArr: ", groupsArr);
  const groups: MyGroup[] = groupsArr || [];

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
    if (user?.id) {
      getZoneScores();
      getGroupsAction({ userId: user?.id });
    }
  }, [user]);

  if (!user) {
    return <></>;
  }

  return (
    <Layout>
      <Header>
        <BackHome />
        <h3>Profile</h3>
      </Header>
      <Content
        style={{ overflowY: "scroll", background: "#333", minHeight: "85vh" }}
      >
        <section
          style={{
            borderLeft: "1px solid #000",
            borderRight: "1px solid #000",
            margin: "auto",
            maxWidth: "50rem",
            textAlign: "left",
            padding: "1rem",
            display: "flex",
            flexFlow: "column",
            flex: "1 1 auto",
            background: "#ccc",
          }}
        >
          <section>
            <span style={{ display: "inline-block" }}>
              <Avatar size={64} icon={<UserOutlined />} />
            </span>
            <span style={{ display: "inline-block", paddingLeft: "1rem" }}>
              <div>
                <h3>{user?.username}</h3>
              </div>
              <div>
                {user?.firstName && user?.lastName
                  ? `${user?.firstName} ${user?.lastName}`
                  : ""}
              </div>
              <div>{user?.email}</div>
            </span>
          </section>

          <section className="profileSection achievements">
            <div>
              <h3>Achievements</h3>
            </div>
            <p>TODO</p>
            <p>0 of 0 Achievements</p>
          </section>

          <section className="profileSection scores">
            <div>
              <h3>Scores</h3>
            </div>
            <div>
              {Object.keys(scores || {}).map((key: ZoneId) => {
                const score = scores ? scores[key] : {};

                switch (key) {
                  default:
                    return (
                      <div
                        style={{
                          padding: ".5rem",
                          border: "1px solid #777",
                          borderRadius: ".5rem",
                          display: "inline-block",
                        }}
                      >
                        <h3>{capitalize(key, "_")}</h3>
                        <div>
                          <b>Wins: </b>
                          <span>{score?.won || 0}</span>
                        </div>
                        <div>
                          <b>Losses: </b>
                          <span>{score?.loss || 0}</span>
                        </div>
                        <div>
                          <b>Ties: </b>
                          <span>{score?.tie || 0}</span>
                        </div>
                      </div>
                    );
                }
              })}
            </div>
          </section>

          <section className="profileSection groups">
            <div>
              <h3>Groups</h3>
            </div>
            <div>
              <p>
                Groups can be family, friends, guild, or corporate sponsored. A
                group can buy and grant credits for its members. There is a
                group chat channel. Maintaining a group takes 1 credit a month.
              </p>
            </div>
            <div>
              <h4>Your groups</h4>
              {groups.length ? (
                <div>
                  <ul>
                    {groups.map((group) => (
                      <li>{group.name}</li>
                    ))}
                  </ul>
                  <Button onClick={() => navigate("/groups")}>Edit</Button>
                </div>
              ) : (
                <div>
                  <Button onClick={() => navigate("/groups")}>Add</Button>
                </div>
              )}
            </div>
          </section>

          <section className="profileSection friends">
            <div>
              <h3>Friends</h3>
            </div>
            <p>TODO - Friend management</p>
          </section>

          <section className="profileSection games">
            <div>
              <h3>Games Summary</h3>
            </div>

            <p>TODO - Show active Games summary. Game level.</p>
            <p>Game level based on games played. Could grant privileges.</p>
          </section>

          <section className="profileSection accounts">
            <div>
              <h3>Alternate login</h3>
            </div>

            <p>TODO - Linked accounts for Facebook, Google, Steam</p>
          </section>

          <section className="profileSection membership">
            <div>
              <h3>Membership</h3>
            </div>

            <p>
              - TODO - Servers cost money but Membership should be affordable,
              like $1-$2 a month or $10 a year.
            </p>
            <p>- $ could buy credits</p>
            <p>
              - Credits could buy server credits for a month, or admission to a
              competition, or maybe digital art
            </p>
            <p>
              Basic games will be free to play. Advanced games will be member
              only.
            </p>
            <p>non members can only be in 1 game at a time</p>
          </section>
        </section>
      </Content>
    </Layout>
  );
}

export function ProfileSection({
  children,
  description,
  title,
}: {
  children: ReactNode;
  description?: string;
  title: string;
}) {
  return (
    <section className="floater-box">
      <div>
        <h3>{title}</h3>
      </div>
      <div>{children}</div>
      {description ? (
        <>
          <Divider />
          <small>{description}</small>
        </>
      ) : null}
    </section>
  );
}
