import { Card, Col, Layout, Row } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { HeaderMenu } from "../components/header-menu";
import { useNavigate } from "react-router-dom";
import store, { useStore } from "../store/basic-store";
import { User } from "../types";
import { useEffect, useState } from "react";

export function GamesPage() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const emailVerifiedShown = useStore("emailVerifiedShown");
  const user = useStore<User>("user");

  useEffect(() => {
    let emailSent = false;
    if (
      user &&
      !user?.email_verified &&
      (!user.email_code_timeout || Date.now() < user.email_code_timeout)
    ) {
      emailSent = true;
    }

    if (user && !emailVerifiedShown && !isModalOpen && !emailSent) {
      store.set("emailVerifiedShown", true);
      setIsModalOpen(true);
    }
  }, [user, isModalOpen, emailVerifiedShown]);

  return (
    <Layout>
      <Header>
        <h1>Fractured Nations Games</h1>
      </Header>
      <Content>
        <HeaderMenu selected="games" />
        <Content>
          <Row>
            <Col span={24}>
              <Card
                title="Tic-Tac-Toe"
                style={{ width: 300, margin: "auto" }}
                onClick={() => navigate("/games/tic-tac-toe")}
              >
                Whether is called Knots and Crosses. Or X's and O's. Its the
                same. Get 3 in a row and you win.
              </Card>
              {/* <h2>Be a high prince! Win Glory. Win honor. Feel the thrill.</h2> */}
            </Col>
          </Row>
        </Content>
      </Content>
    </Layout>
  );
}
