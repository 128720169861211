import { useState } from "react";
import { useStore } from "../store/basic-store";
import { ZoneUser } from "../types";
import { Select } from "antd";
import { findUser } from "../service/group.action";

export function UserSelect({
  onSelect,
}: {
  onSelect: (value: string[]) => void;
}) {
  const [value, setValue] = useState<{ label: string; value: string }[]>([]);
  const users = useStore<ZoneUser[]>("userSearch");
  const options: { label: string; value: string }[] =
    users?.map((u) => ({
      label: u.username,
      value: u.username.toLowerCase(),
    })) || [];
  console.log("users: ", users);
  return (
    <Select
      labelInValue
      mode="multiple"
      filterOption={false}
      filterSort={(optionA, optionB) =>
        optionA.label > optionB.label ? 1 : -1
      }
      onSearch={fetcher}
      placeholder="Select users"
      value={value}
      onChange={(newValue: { label: string; value: string }) => {
        setValue(newValue);
        if (newValue && onSelect) {
          console.log("newValue: ", newValue);
          onSelect(newValue.map((v) => v.value));
        }
      }}
      options={options}
      style={{ width: "100%" }}
    />
  );
}

let lastFetch = Date.now();
let searchValue = "";
let lastSearch = "";
function fetcher(str?: string) {
  const now = Date.now();
  if (str && str?.length > 2) {
    searchValue = str;
    console.log("Search This: ", str);
    if (now - lastFetch > 300) {
      lastSearch = str;
      findUser({ search: str });
    } else {
      setTimeout(() => {
        if (lastSearch !== searchValue) {
          fetcher(searchValue);
        }
      }, 301);
    }
  }
}
